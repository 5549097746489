import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  
  const [response, setResponse] = React.useState("none");
  
  let url = process.env.NODE_ENV === "production"
  ? 'https://nova-test.jonnz.com/php/index.php'
  : 'http://localhost:8888/nova-test/phpSrc/index.php';
  
  async function onClickHandler() {
    
    {/* let url = process.env.NODE_ENV === "production"
      ? 'https://nova-test.jonnz.com/php/index.php'
      : 'http://localhost:8888/nova-test/phpSrc/'; */}
    let response = await fetch(url, {mode: 'no-cors'});
    console.log(response);
    let text = await response.text();
    
    setResponse(text);
  }
   
  
  return (
    <div className="App">
      <input type="button" value="button" onClick={onClickHandler}/><br />
      {response}<br />
      {url}
    </div>
  );
}

export default App;

